<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        <router-link :to="'/app/invitations'" href="#">{{ $t("invitation.header") }} </router-link>
        <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ invitation.name }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-card :title="$t(`invitation.card_invitation`)" class="mb-3 shadow">
            <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.name`)" label-for="inv-name">
                        <b-form-input id="inv-name" type="text" v-model="invitation.name" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.user`)" label-for="inv-user">
                        <b-form-input name="inv-user" v-model="invitation.user.name" disabled></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.maxuses`)" label-for="inv-max-uses">
                        <b-form-input id="inv-max-uses" type="text"  v-model="invitation.max_uses" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.countuses`)" label-for="inv-count-uses">
                        <b-form-input id="inv-count-uses" type="text"  v-model="invitation.count_uses" disabled ></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.expiresat`)" label-for="inv-expires">
                        <b-row><b-col>
                            <b-form-datepicker today-variant="info" v-model="invitation.expires_at_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="inv-expires" :placeholder="$t(`common.nodateselected`)">
                            </b-form-datepicker>
                        </b-col><b-col>
                            <b-form-timepicker v-model="invitation.expires_at_time" :locale="$i18n.locale" >
                            </b-form-timepicker>
                        </b-col></b-row>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group :label="$t(`invitation.token`)" label-for="inv-token">
                        <b-form-input id="inv-token" type="text" v-model="invitation.token" disabled></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("invitation.btn_save") }}</b-button>
                </b-col></b-row>

            </b-form>
        </b-card>
        <br />
        
        <b-card :title="$t(`invitation.card_auctions`)" class="mb-3 shadow">
            <b-row><b-col>
            <br />
            <div class="table-scrollable">
                <b-table striped hover :items="invitation.auctions" :fields="auctionfields">
                    <template #cell(start_date)="data">
                        {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                    </template>
                    <template #cell(end_date)="data">
                        {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                    </template>
                    <template #cell(actions)="data">
                        <b-link class="m-1" href="#"  @click.prevent="requestConfirmation(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                    </template>
                </b-table>
            </div>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group :label="$t(`invitation.auction`)" label-for="auction-select">
                    <b-form-select id="auction-select" text-field="display_name" value-field="id" :options="auctions" v-model="selectedAuction"></b-form-select>
                </b-form-group>
                <br />
                <b-button class="button-margin" variant="outline-primary" @click="addAuctionToInvitation"><b-icon icon="cart-plus" />{{ $t("invitation.btn_addauction") }}</b-button>
            </b-col></b-row>
        </b-card>
    </div>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`invitation.modal_title`)">
        <div class="d-block text-center">
            <p v-if="auctionContext">{{ $t("invitation.modal_note1") }} <strong>'{{auctionContext.title}}'</strong> {{ $t("invitation.modal_note2") }}</p>
        </div>
        <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete">{{ $t("invitation.btn_delete") }}</b-button>
    </b-modal>
</div>
</template>
<script>
import {_} from 'vue-underscore';
import {mapGetters, mapActions} from 'vuex';
import invitationService from '../../../../services/InvitationService';
import {helpers, required, numeric} from 'vuelidate/lib/validators';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'invitation-createedit',
    mixins: [dateHelper],
    data() {
        return {
            editMode: false,
            doneLoading: false,
            invitation: {
                name: '',
                max_uses: '',
                expires_at_time: '00:00',
                user: {}
            },
            selectedAuction: '',
            auctionContext: '',

        }
    },
    created() {
        return this.fetchAuctions()
            .then(() => {
                this.$store.commit('loading/setLoading', false);

                if(this.$route.params.id) {
                    this.$store.commit('loading/setLoading', true);
                    invitationService.fetch(this.$route.params.id)
                        .then(data => {
                            this.setInvitation(data.data);
                            this.editMode = true;
                            this.doneLoading = true;
                            this.$store.commit('loading/setLoading', false);
                        });
                }else {
                    this.doneLoading = true;
                }
            });
    },
    validations: {
        invitation: {
            name: {
                required
            },
            max_uses: {
                numeric
            }
        }
    },
    methods: {
        ...mapActions({
            fetchAuctions: 'invitations/fetchAuctions'
        }),
        setInvitation(invitation) {
            invitation.expires_at = this.$moment(invitation.expires_at);
            invitation.expires_at_date = invitation.expires_at.clone().startOf('day').toDate();
            invitation.expires_at_time = invitation.expires_at.format('HH:mm');
            this.invitation = invitation;
        },
        onSubmit() {
            this.$v.$touch();
            this.invitation.expires_at = this.dateTimeCombine(this.invitation.expires_at_date, this.invitation.expires_at_time);

            if(this.$v.$error) {
                return;
            }

            if(!this.editMode) {
                this.create();
            }else {
                this.update();
            }
        },
        create() {
            this.$store.commit('loading/setLoading', true);
            invitationService.create(this.invitation)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if(response.data.id !== undefined) {
                        this.setInvitation(response.data);
                        this.editMode = true;

                        this.$notify({
                            group: 'global',
                            title: this.$t("invitation.notify_invitationsuccess_title"),
                            type: 'success',
                            text: this.$t("invitation.notify_invitationsuccess_message")
                        });

                    }else {
                        this.$notify({
                        group: 'global',
                        title: this.$t("invitation.notify_invitationsuccess_error"),
                        type: 'error',
                        text: response.data.message
                    });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: this.$t("invitation.notify_invitationsuccess_error"),
                        type: 'error',
                        text: error.message
                    });
                });
        },
        update() {
            this.$store.commit('loading/setLoading', true);
            invitationService.update(this.invitation.id, this.invitation)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if((response.success === undefined) && ((response.data.success === undefined) || (response.data.success === true))) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("invitation.notify_invitationsave_title"),
                            type: 'success',
                            text: this.$t("invitation.notify_invitationsave_message")
                        });

                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("invitation.notify_invitationsave_error"),
                            type: 'error',
                            text: (response.data === undefined) ? response.message : response.data.message,
                        });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: this.$t("invitation.notify_invitationsave_error"),
                        type: 'error',
                        text: error.message
                    });
                });
        },
        requestConfirmation(auction) {
            this.auctionContext = auction;
            this.$refs.confirmationModal.show();
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.$store.commit('loading/setLoading', true);
            invitationService.unassignAuction(this.invitation.id, this.auctionContext.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("invitation.notify_unassign_title"),
                            type: 'error',
                            text: response.data.message
                        });
                    }

                    this.auctionContext = null;
                    this.fetchAssignedAuctions();
                });
        },
        searchAuction(query) {
            invitationService.searchAuction(query)
                .then(response => {
                    this.availableAuctions = response.data;
                });
        },
        addAuctionToInvitation() {
            if(this.selectedAuction) {
                this.$store.commit('loading/setLoading', true);

                invitationService.assignAuction(this.invitation.id, this.selectedAuction)
                    .then(response => {
                        this.selectedAuction = null;
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {

                            this.$notify({
                                group: 'global',
                                title: this.$t("invitation.notify_assign_title"),
                                type: 'error',
                                text: response.data.message
                            });

                            return;
                        }
                        this.fetchAssignedAuctions();
                        this.auctionSearch = '';
                        this.selectedAuction = null;
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);
                        this.$notify({
                            group: 'global',
                            title: this.$t("invitation.notify_assign_title"),
                            type: 'error',
                            text: error.message
                        });
                    });
            }
        },
        fetchAssignedAuctions() {
            return invitationService.fetchAuctions(this.invitation.id)
                .then(response => {
                    this.invitation.auctions = response.data;
                });
        },
    },
    computed: {
        ...mapGetters({
            auctions: 'invitations/auctions',
        }),
        auctionfields() {
            return [
                {   key: 'title',               label: this.$t(`auction_list.auction.column_title`)      },
                {   key: 'organizer.company',   label: this.$t(`auction_list.auction.column_organizer`)  },
                {   key: 'start_date',          label: this.$t(`auction_list.auction.column_start`)      },
                {   key: 'end_date',            label: this.$t(`auction_list.auction.column_end`)        },
                {   key: 'status',              label: this.$t(`auction_list.auction.column_status`)     },
                {   key: 'actions',             label: this.$t(`auction_list.auction.column_actions`)    }
            ];
        },
    },
    watch: {
        auctionSearch: _.debounce(function(query) {
            this.searchAuction(query)
        } , 200)
    }
};
</script>
