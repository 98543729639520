<template>
    <div>
        <b-card class="mb-3 shadow" :title="$t(`settings.card_contractstates`)">
            <b-button class="button-margin" variant="primary" @click.prevent="showNewItemForm" v-if="hasRole('Administrator')||hasRole('Berater')"><b-icon icon="folder-plus" />{{ $t("settings.new") }}</b-button>
            <br />
            <div class="table-scrollable">
                <b-table striped hover :items="contractStates" :fields="contractstatefields">
                    <template #cell(actions)="data">
                        <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                        <b-link class="m-1" href="#" v-show="data.index < contractStates.length-1" @click="moveItemDown(data.item.id)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                        <b-link class="m-1" href="#" @click="editItem(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                        <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                    </template>
                </b-table>
            </div>
            <br />
            <b-modal ref="confirmationModal" hide-footer :title="$t(`settings.modal_title_delete`)">
                <div class="d-block text-center">
                    <p v-if="itemContext">{{ $t(`settings.delete_message`) }}</p>
                </div>
                <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t("settings.btn_delete") }}</b-button>
            </b-modal>
        </b-card>
        <EditContractState ref="EditContractState" :savedCallback="reload" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import EditContractState from './EditContractState';
    import ContractService from '../../../../../services/ContractService';

    export default {
        name: 'contractstates',
        components: {EditContractState},
        props: [],
        data() {
            return {
                itemContext: null,
                currentItem: {}
            }
        },
        created() {
            this.fetchContractStates();
        },
        methods: {
            ...mapActions({
                fetchContractStates: 'contracts/fetchContractStates',
                deleteContractStates: 'contracts/deleteContractStates'
            }),
            showNewItemForm() {
                this.currentItemEditMode = false;
                this.$refs.EditContractState.setItem({});
                this.$refs.EditContractState.showModal();
            },
            editItem(item) {
                this.$refs.EditContractState.setItem(item);
                this.$refs.EditContractState.showModal();
                this.$refs.EditContractState.enterEditMode();
            },
            requestConfirmation(item) {
                this.itemContext = item;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                ContractService.deleteContractState(this.itemContext.id)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Entfernen der Vertraulichkeit.',
                                type: 'error',
                                text: response.data.message
                            });
                        }

                        this.itemContext = null;
                        this.reload();
                    });
            },
            reload() {
                this.fetchContractStates();
            },
            moveItemUp(contractstateId) {
                this.$store.commit('loading/setLoading', true);
                ContractService.moveContractStateUp(contractstateId)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        this.fetchContractStates();
                    });
            },
            moveItemDown(contractstateId) {
                this.$store.commit('loading/setLoading', true);
                ContractService.moveContractStateDown(contractstateId)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        this.fetchContractStates();
                    });
            }
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                contractStates: 'contracts/contractStates'
            }),
            contractstatefields() {
                return [
                    { key: 'id',                                label: 'ID'                      },
                    { key: 'order',                             label: 'Reihenfolge'             },
                    { key: 'contract_state',                    label: 'Vertragsstatus'          },
                    { key: 'actions',                           label: 'Aktionen'                },
                ];
            },
        }
    }
</script>