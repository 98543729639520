<template>
<b-modal ref="fileDialog" hide-footer :title="fileDialogTitle">
    <b-container>
        <b-form @submit.prevent="onFileUploadSubmit">

            <b-row><b-col>
                <b-form-group label="Bezeichung der Anlage" label-for="appendix-name">
                    <b-form-input id="appendix-name" type="text" v-model="currentFile.appendix_name" :state="!$v.currentFile.appendix_name.$error ? null : false"></b-form-input>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Anlagenart" label-for="appendix-type">
                    <b-form-select text-field="appendix_type" value-field="id" :options="appendixTypes" v-model="currentFile.appendix_type_id" :state="!$v.currentFile.appendix_type_id.$error ? null : false"></b-form-select>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Dokument angelegt durch" label-for="appendix-user">
                    <b-form-input name="appendix-user" :value="currentFile.appendix_created_by?currentFile.appendix_created_by.name:''" disabled></b-form-input>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Laufzeitbeginn der Anlage" label-for="appendix-starts">
                        <b-form-datepicker today-variant="info" v-model="currentFile.appendix_startdate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="appendix-starts" :placeholder="$t(`common.nodateselected`)">
                        </b-form-datepicker>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Laufzeitende der Anlage" label-for="appendix-ends">
                        <b-form-datepicker today-variant="info" v-model="currentFile.appendix_enddate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="appendix-ends" :placeholder="$t(`common.nodateselected`)">
                        </b-form-datepicker>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Notizen zur Anlage" label-for="appendix-description">
                    <b-form-textarea id="appendix-description" v-model="currentFile.appendix_description" :state="!$v.currentFile.appendix_description.$error ? null : false" rows="3" max-rows="3" no-resize />
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Suchbegriffe, Schlagworte zum Dokument" label-for="appendix-keywords">
                    <b-form-textarea id="appendix-keywords" v-model="currentFile.appendix_keywords" rows="3" max-rows="3" no-resize />
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Dateiname" label-for="appendix-filename" v-show="currentFile.id">
                    <b-form-input id="appendix-filename" v-model="currentFile.appendix_filename" :state="editingFile && !$v.currentFile.appendix_filename.$error ? null : false" />
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-form-group label="Dateityp" label-for="file-type" v-show="currentFile.id">
                    <b-form-input id="file-type" v-model="currentFile.appendix_mimetype" readonly/>
                </b-form-group>
            </b-col></b-row>

            <b-row v-if="!editingFile"><b-col>
                <b-form-group label="Anlage zum Vertrag" label-for="appendix-file">
                    <b-form-file id="appendix-file" placeholder="Anlage wählen..." v-model="currentFile.file" :state="!editingFile && !$v.currentFile.file.$error ? null : false" accept/>
                </b-form-group>
            </b-col></b-row>

            <b-row><b-col>
                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{fileDialogSaveButtonText}}</b-button>
            </b-col></b-row>

        </b-form>
    </b-container>
</b-modal>
</template>
<script>
import numberHelper from '../../../../../helpers/numberHelper';
import objectHelper from '../../../../../helpers/objectHelper';
import contractService from '../../../../../services/ContractService';
import { transformToFormData } from '../../../../mixins/transformToFormData';
import {mapGetters, mapActions} from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
export default {
    name: 'create-appendix-modal',
    props: ['savedCallback', 'contract'],
    mixins: [transformToFormData],
    data() {
        return {
            editingFile: false,
            currentFile: {},
        }
    },
    validations() {
        let validations = {
            currentFile: {
                appendix_name:        { required },
                appendix_type_id:     { required },
                appendix_description: { required },
                appendix_filename:    { function(value) {return true;} },
                file:                 { function(value) {return true;} },
            }
        };

        if(!this.editingFile) {
            validations['currentFile']['file'] = { required };
        } else {
            validations['currentFile']['appendix_filename'] = { required };
        }

        return validations;
    },
    methods: {
        enterEditMode() {
            if (this.currentFile.appendix_startdate) {this.currentFile.appendix_startdate = this.$moment(this.currentFile.appendix_startdate).toDate(); }
            if (this.currentFile.appendix_enddate) {this.currentFile.appendix_enddate = this.$moment(this.currentFile.appendix_enddate).toDate(); }
            this.editingFile = true;
        },
        leaveEditMode() {
            this.editingFile = false;
        },
        showModal() {
            this.$v.$reset();
            this.editingFile = false;
            this.currentFile = {};
            this.$refs.fileDialog.show();
        },
        hideModal() {
            this.$refs.fileDialog.hide();
            this.currentFile = {};
            this.$v.$reset();
        },
        setFileItem(currentFile) {
            this.currentFile = currentFile;
        },
        onFileUploadSubmit() {
            this.$v.$touch();

            if(this.$v.$error) {
                this.$notify({
                    group: 'global',
                    title: 'Eingabeprüfung',
                    type: 'error',
                    text: 'Eingabe unvollständig oder fehlerhaft'
                });
                return;
            }

            this.$store.commit('loading/setLoading', true);
            if(!this.editingFile) {
                contractService.createAppendix(this.contract.id, this.transformToFormData(this.currentFile, []))
                    .then(response => {

                        this.$notify({
                                group: 'global',
                                title: 'Anlage hochgeladen.',
                                type: 'success',
                                text: 'Die Anlage wurde erfolgreich hochgeladen.'
                            });

                        this.$refs.fileDialog.hide();

                        this.currentFile = {};

                        if(this.savedCallback) {
                            this.savedCallback();
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);

                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Hochladen der Anlage',
                            type: 'error',
                            text: error.message
                        });
                    })
            } else {
                contractService.updateAppendix(this.contract.id, this.currentFile.id, this.transformToFormData(this.currentFile, [], 'PUT')).then(response => {
                    this.$notify({
                        group: 'global',
                        title: 'Anlage aktualisiert.',
                        type: 'success',
                        text: 'Die Anlage wurde erfolgreich aktualisiert.'
                    });

                    if(this.savedCallback) {
                            this.savedCallback();
                        }

                    this.$refs.fileDialog.hide();

                    this.currentFile = {};
                })
                .catch(error => {
                    this.$notify({
                            group: 'global',
                            title: 'Fehler beim Aktualisieren der Anlage',
                            type: 'error',
                            text: error.message
                        });
                })
            }
        },
    },
    computed: {
        ...mapGetters({
            appendixTypes: 'contracts/appendixTypes',
        }),
        fileDialogTitle() {
            return !this.editingFile ? 'Neue Anlage hochladen' : 'Anlage editieren';
        },
        fileDialogSaveButtonText() {
            return !this.editingFile ? 'Hochladen' : 'Speichern';
        }
    }
}
</script>
