import axios from 'axios';
var querystring = require('querystring');

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetch(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts', formData)
            .then(response => response)
            .catch(error => error.response);
    },
    update(contractId, payload) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId, querystring.stringify(payload),
                         { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(response => response)
            .catch(error => error.response);
    },
    delete(contractId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchSelectionLists() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/selectionLists')
            .then(response => response.data)
            .catch(error => error.response);
    },

    availableAuctions() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/auctions')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchAuctions(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/getauctions/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    assignAuction(contractId, auctionId) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignauction/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    unassignAuction(contractId, auctionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/unassignauction/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },


    createContractFile(contractId, payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractFile(contractId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadContractFile(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile', { responseType:"blob" })
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },


    createAppendix(contractId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listAppendices(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateAppendix(contractId, appendixId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId, payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteAppendix(contractId, appendixId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadAppendix(contractId, appendixId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId + '/download', { responseType:"blob" })
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },


    searchUser(query) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/usersearch/' + query)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    availableUsers() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/users')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchUsers(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/getusers/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    addUser(contractId, user) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignedUsers/' + user, '')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    unassignUser(contractId, user) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignedUsers/' + user, '')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getAssignedUsers(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/'+ contractId + '/assignedUsers')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    listContractConfidentialities() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractExtensionTypes() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractOccasions() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractRiskLevels() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractStates() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractstate/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractStrategicImportances() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractSubjects() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractsubject/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractTerminationForms() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractTypes() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contracttype/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listContractValueClusters() {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    fetchContractConfidentialities(contractconfidentialityId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/fetch' + contractconfidentialityId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractExtensionTypes(contractextensiontypeId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/fetch' + contractextensiontypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractOccasions(contractoccasionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/fetch' + contractoccasionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractRiskLevels(contractrisklevelId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/fetch' + contractrisklevelId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractStates(contractstateId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractstate/fetch' + contractstateId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractStrategicImportances(contractstrategicimportanceId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/fetch' + contractstrategicimportanceId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractSubjects(contractsubjectId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractsubject/fetch' + contractsubjectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractTerminationForms(contractterminationformId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/fetch' + contractterminationformId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractTypes(contracttypeId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contracttype/fetch' + contracttypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchContractValueClusters(contractvalueclusterId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/fetch' + contractvalueclusterId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    createContractConfidentiality(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractExtensionType(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractOccasion(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractRiskLevel(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractState(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstate/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractStrategicImportance(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractSubject(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractsubject/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractTerminationForm(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractType(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contracttype/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createContractValueCluster(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },

    updateContractConfidentiality(contractconfidentialityId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/update/' + contractconfidentialityId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractExtensionType(contractextensiontypeId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/update/' + contractextensiontypeId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractOccasion(contractoccasionId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/update/' + contractoccasionId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractRiskLevel(contractrisklevelId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/update/' + contractrisklevelId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractState(contractstateId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstate/update/' + contractstateId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractStrategicImportance(contractstrategicimportanceId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/update/' + contractstrategicimportanceId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractSubject(contractsubjectId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractsubject/update/' + contractsubjectId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractTerminationForm(contractterminationformId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/update/' + contractterminationformId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractType(contracttypeId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contracttype/update/' + contracttypeId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateContractValueCluster(contractvalueclusterId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/update/' + contractvalueclusterId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    deleteContractConfidentiality(contractconfidentialityId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/delete/' + contractconfidentialityId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractExtensionType(contractextensiontypeId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/delete/' + contractextensiontypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractOccasion(contractoccasionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/delete/' + contractoccasionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractRiskLevel(contractrisklevelId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/delete/' + contractrisklevelId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractState(contractstateId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractstate/delete/' + contractstateId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractStrategicImportance(contractstrategicimportanceId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/delete/' + contractstrategicimportanceId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractSubject(contractsubjectId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractsubject/delete/' + contractsubjectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractTerminationForm(contractterminationformId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/delete/' + contractterminationformId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractType(contracttypeId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contracttype/delete/' + contracttypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractValueCluster(contractvalueclusterId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/delete/' + contractvalueclusterId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    moveContractConfidentialityUp(contractconfidentialityId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/moveUp/' + contractconfidentialityId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractExtensionTypeUp(contractextensiontypeId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/moveUp/' + contractextensiontypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractOccasionUp(contractoccasionId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/moveUp/' + contractoccasionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractRiskLevelUp(contractrisklevelId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/moveUp/' + contractrisklevelId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractStateUp(contractstateId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstate/moveUp/' + contractstateId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractStrategicImportanceUp(contractstrategicimportanceId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/moveUp/' + contractstrategicimportanceId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractSubjectUp(contractsubjectId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractsubject/moveUp/' + contractsubjectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractTerminationFormUp(contractterminationformId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/moveUp/' + contractterminationformId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractTypeUp(contracttypeId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contracttype/moveUp/' + contracttypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractValueClusterUp(contractvalueclusterId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/moveUp/' + contractvalueclusterId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    moveContractConfidentialityDown(contractconfidentialityId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractconfidentiality/moveDown/' + contractconfidentialityId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractExtensionTypeDown(contractextensiontypeId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractextensiontype/moveDown/' + contractextensiontypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractOccasionDown(contractoccasionId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractoccasion/moveDown/' + contractoccasionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractRiskLevelDown(contractrisklevelId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractrisklevel/moveDown/' + contractrisklevelId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractStateDown(contractstateId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstate/moveDown/' + contractstateId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractStrategicImportanceDown(contractstrategicimportanceId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractstrategicimportance/moveDown/' + contractstrategicimportanceId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractSubjectDown(contractsubjectId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractsubject/moveDown/' + contractsubjectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractTerminationFormDown(contractterminationformId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractterminationform/moveDown/' + contractterminationformId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractTypeDown(contracttypeId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contracttype/moveDown/' + contracttypeId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveContractValueClusterDown(contractvalueclusterId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/settings/contractvaluecluster/moveDown/' + contractvalueclusterId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },


    listReminders(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    createReminder(contractId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateReminder(contractId, contractreminderId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder/' + contractreminderId , payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response)); 
    },
    deleteReminder(contractId, contractreminderId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder/' + contractreminderId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response)); 
    },
    fetchActiveReminders() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/activeReminders')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
