<template>
    <div>
        <b-card class="mb-3 shadow">
            <h3>{{questionnaire.name}}</h3>
            <template v-for="question in questionnaire.questions">
                <b-row class="my-1">
                    <b-col sm="7"><label>{{question.questiontext}}</label></b-col>
                    <b-col sm="3">
                        <template v-if="question.type === 1"><!--int-->
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 2"><!--double-->
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 3"><!--text-->
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 4"><!--file-->
                            <b-link v-if="answers[question.id+'_downloadKey']" @click.prevent="downloadFile(answers[question.id+'_downloadKey'], answers[question.id+'_filename'], answers[question.id+'_mimetype'])">{{ answers[question.id+'_filename'] }}</b-link>
                        </template>
                        <template v-if="question.type === 5"><!--options-->
                            {{ getAnswerOptionText(question, answers[question.id]) }}
                        </template>
                        <template v-if="question.type === 6"><!--checkboxes-->
                            {{ getAnswerCheckboxText(question, answers[question.id]) }}
                        </template>
                    </b-col>
                    <b-col sm="2">
                        <template v-if="question.type === 5"><!--options-->
                            {{  getScoreForOptionsQuestion(question, answers[question.id]) }}
                        </template>
                        <template v-if="question.type === 6"><!--checkboxes-->
                            {{  getScoreForCheckboxQuestion(question, answers[question.id]) }}
                        </template>
                        <template v-else>
                        </template>

                    </b-col>
                </b-row>
            </template>
            <!--<b-button class="button-margin" @click.prevent="downloadQuestionnaire(questionnaire.id)">{{ $t("questionnaire.btn_download") }}</b-button>-->
            <br />
        </b-card>
    </div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'profile-questionnaire-answers',
    props:['questionnaire', 'answers'],
    created() {
    },
    methods: {
        downloadQuestionnaire(questionnaireId) {
            questionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            questionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        },
        getAnswerOptionText(question, answer) {
            if (!answer) {
                return '';
            }
            const o = question.answeroptions.find(i => (i.id == answer));
            if (!o) {
                return '';
            }
            return o.answer_text;
        },
        getAnswerCheckboxText(question, answer) {
            if (!answer) {
                return '';
            }
            if (answer == []) {
                return '[]';
            }
            const texts = answer.map(a => this.getAnswerOptionText(question, a));
            return '[' + texts.join(', ') + ']';
        },
        getScoreForOptionsQuestion(question, answer) {
            if (!answer) {
                return 0;
            }
            const o = question.answeroptions.find(i => (i.id == answer));
            if (!o) {
                return 0;
            }
            return o.answer_score;
        },
        getScoreForCheckboxQuestion(question, answer) {
            if (!answer) {
                return 0;
            }
            if (answer == []) {
                return 0;
            }
            const scores = answer.map(a => this.getScoreForOptionsQuestion(question, a));
            return scores.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        },
    }
};
</script>
