
import store from '../../store/store';
import ContractService from '../../services/ContractService';

const state = {
    contracts: [],
    auctions: [],
    users: [],
    appendixTypes: [],
    contractConfidentialities: [],
    contractExtensionTypes: [],
    contractOccasions: [],
    contractRiskLevels: [],
    contractStates: [],
    contractStrategicImportances: [],
    contractSubjects: [],
    contractTerminationForms: [],
    contractTypes: [],
    contractValueClusters: [],
    resubmissionReasons: [],
    resubmissionStates: [],
    //callForBids: [],
    //organizers: []
};

const getters = {
    contracts() {
        return state.contracts;
    },
    auctions() {
        return state.auctions;
    },
    users() {
        return state.users;
    },
    appendixTypes() {
        return state.appendixTypes;
    },
    contractConfidentialities() {
        return state.contractConfidentialities;
    },
    contractExtensionTypes() {
        return state.contractExtensionTypes;
    },
    contractOccasions() {
        return state.contractOccasions;
    },
    contractRiskLevels() {
        return state.contractRiskLevels;
    },
    contractStates() {
        return state.contractStates;
    },
    contractStrategicImportances() {
        return state.contractStrategicImportances;
    },
    contractSubjects() {
        return state.contractSubjects;
    },
    contractTerminationForms() {
        return state.contractTerminationForms;
    },
    contractTypes() {
        return state.contractTypes;
    },
    contractValueClusters() {
        return state.contractValueClusters;
    },
    resubmissionReasons() {
        return state.resubmissionReasons;
    },
    resubmissionStates() {
        return state.resubmissionStates;
    },
};

const actions = {
    fetchContracts({commit}, archived) {
        store.dispatch('loading/setLoading', true);
        return ContractService.list(true, archived)
            .then(data =>{
                commit("setContracts", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchAuctions({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.availableAuctions()
            .then(data =>{
                commit("setAuctions", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchUsers({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.availableUsers()
            .then(data => {
                commit('setUsers', data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchSelectionLists({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.fetchSelectionLists()
            .then(data => {
                // Todo: Weitere Daten im Store ablegen
                commit('setAppendixTypes', data.data.appendixTypes);
                commit('setContractConfidentialities', data.data.contractConfidentialities);
                commit('setContractExtensionTypes', data.data.contractExtensionTypes);
                commit('setContractOccasions', data.data.contractOccasions);
                commit('setContractRiskLevels', data.data.contractRiskLevels);
                commit('setContractStates', data.data.contractStates);
                commit('setContractStrategicImportances', data.data.contractStrategicImportances);
                commit('setContractSubjects', data.data.contractSubjects);
                commit('setContractTerminationForms', data.data.contractTerminationForms);
                commit('setContractTypes', data.data.contractTypes);
                commit('setContractValueClusters', data.data.contractValueClusters);
                commit('setResubmissionReasons', data.data.resubmissionReasons);
                commit('setResubmissionStates', data.data.resubmissionStates);
                store.dispatch('loading/setLoading', false);
            });
    },

    fetchContractConfidentialities({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractConfidentialities()
            .then(data =>{
                commit("setContractConfidentialities", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractExtensionTypes({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractExtensionTypes()
            .then(data =>{
                commit("setContractExtensionTypes", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractOccasions({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractOccasions()
            .then(data =>{
                commit("setContractOccasions", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractRiskLevels({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractRiskLevels()
            .then(data =>{
                commit("setContractRiskLevels", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractStates({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractStates()
            .then(data =>{
                commit("setContractStates", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractStrategicImportances({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractStrategicImportances()
            .then(data =>{
                commit("setContractStrategicImportances", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractSubjects({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractSubjects()
            .then(data =>{
                commit("setContractSubjects", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractTerminationForms({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractTerminationForms()
            .then(data =>{
                commit("setContractTerminationForms", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractTypes({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractTypes()
            .then(data =>{
                commit("setContractTypes", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchContractValueClusters({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.listContractValueClusters()
            .then(data =>{
                commit("setContractValueClusters", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },

    deleteContractConfidentialities({commit}, contractconfidentialityId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractConfidentiality(contractconfidentialityId)
            .then(data => {
                store.dispatch("contracts/fetchContractConfidentialities");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractExtensionTypes({commit}, contractextensiontypeId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractExtensionType(contractextensiontypeId)
            .then(data => {
                store.dispatch("contracts/fetchContractExtensionTypes");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractOccasions({commit}, contractoccasionId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractOccasion(contractoccasionId)
            .then(data => {
                store.dispatch("contracts/fetchContractOccasions");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractRiskLevels({commit}, contractrisklevelId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractRiskLevel(contractrisklevelId)
            .then(data => {
                store.dispatch("contracts/fetchContractRiskLevels");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractStates({commit}, contractstateId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractState(contractstateId)
            .then(data => {
                store.dispatch("contracts/fetchContractStates");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractStrategicImportances({commit}, contractstrategicimportanceId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractStrategicImportance(contractstrategicimportanceId)
            .then(data => {
                store.dispatch("contracts/fetchContractStrategicImportances");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractSubjects({commit}, contractsubjectId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractSubject(contractsubjectId)
            .then(data => {
                store.dispatch("contracts/fetchContractSubjects");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractTerminationForms({commit}, contractterminationformId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractTerminationForm(contractterminationformId)
            .then(data => {
                store.dispatch("contracts/fetchContractTerminationForms");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractTypes({commit}, contracttypeId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractType(contracttypeId)
            .then(data => {
                store.dispatch("contracts/fetchContractTypes");
                store.dispatch('loading/setLoading', false);
            })
    },
    deleteContractValueClusters({commit}, contractvalueclusterId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.deleteContractValueCluster(contractvalueclusterId)
            .then(data => {
                store.dispatch("contracts/fetchContractValueClusters");
                store.dispatch('loading/setLoading', false);
            })
    },

    clearContractConfidentialities({commit}) {
        commit('setContractConfidentialities', []);
    },
    clearContractExtensionTypes({commit}) {
        commit('setContractExtensionTypes', []);
    },
    clearContractOccasions({commit}) {
        commit('setContractOccasions', []);
    },
    clearContractRiskLevels({commit}) {
        commit('setContractRiskLevels', []);
    },
    clearContractStates({commit}) {
        commit('setContractStates', []);
    },
    clearContractStrategicImportances({commit}) {
        commit('setContractStrategicImportances', []);
    },
    clearContractSubjects({commit}) {
        commit('setContractSubjects', []);
    },
    clearContractTerminationForms({commit}) {
        commit('setContractTerminationForms', []);
    },
    clearContractTypes({commit}) {
        commit('setContractTypes', []);
    },
    clearContractValueClusters({commit}) {
        commit('setContractValueClusters', []);
    },

    // TODO: Hier wird commit und data in "grau" angezeigt. Fehlt hier noch etwas?
    delete({commit}, contractId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.delete(contractId)
            .then(data => {
                store.dispatch("contracts/fetchContracts");
                store.dispatch('loading/setLoading', false);
            });
    },
    clear({commit}) {
        commit('setContracts', []);
        commit('setUsers', []);
        commit('setAuctions', []);
        commit('setAppendixTypes', []);
        commit('setContractConfidentialities', []);
        commit('setContractExtensionTypes', []);
        commit('setContractOccasions', []);
        commit('setContractRiskLevels', []);
        commit('setContractStates', []);
        commit('setContractStrategicImportances', []);
        commit('setContractSubjects', []);
        commit('setContractTerminationForms', []);
        commit('setContractTypes', []);
        commit('setContractValueClusters', []);
        commit('setResubmissionReasons', []);
        commit('setResubmissionStates', []);
    }
}

const mutations = {
    setContracts(state, contracts) {
        state.contracts = contracts;
    },
    setAuctions(state, auctions) {
        state.auctions = auctions;
    },
    setUsers(state, users) {
        state.users = users;
    },
    setAppendixTypes(state, appendixTypes) {
        state.appendixTypes = appendixTypes;
    },
    setContractConfidentialities(state, contractConfidentialities) {
        state.contractConfidentialities = contractConfidentialities;
    },
    setContractExtensionTypes(state, contractExtensionTypes) {
        state.contractExtensionTypes = contractExtensionTypes;
    },
    setContractOccasions(state, contractOccasions) {
        state.contractOccasions = contractOccasions;
    },
    setContractRiskLevels(state, contractRiskLevels) {
        state.contractRiskLevels = contractRiskLevels;
    },
    setContractStates(state, contractStates) {
        state.contractStates = contractStates;
    },
    setContractStrategicImportances(state, contractStrategicImportances) {
        state.contractStrategicImportances = contractStrategicImportances;
    },
    setContractSubjects(state, contractSubjects) {
        state.contractSubjects = contractSubjects;
    },
    setContractTerminationForms(state, contractTerminationForms) {
        state.contractTerminationForms = contractTerminationForms;
    },
    setContractTypes(state, contractTypes) {
        state.contractTypes = contractTypes;
    },
    setContractValueClusters(state, contractValueClusters) {
        state.contractValueClusters = contractValueClusters;
    },
    setResubmissionReasons(state, resubmissionReasons) {
        state.resubmissionReasons = resubmissionReasons;
    },
    setResubmissionStates(state, resubmissionStates) {
        state.resubmissionStates = resubmissionStates;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
