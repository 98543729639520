<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t("settings.listheader") }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <ContractConfidentialities ref="contractconfidentialities"></ContractConfidentialities>
            <ContractExtensionTypes ref="contractextensiontypes"></ContractExtensionTypes>
            <ContractOccasions ref="contractoccasions"></ContractOccasions>
            <ContractRiskLevels ref="contractrisklevels"></ContractRiskLevels>
            <ContractStates ref="contractstates"></ContractStates>
            <ContractStrategicImportances ref="contractstrategicimportances"></ContractStrategicImportances>
            <ContractSubjects ref="contractsubjects"></ContractSubjects>
            <ContractTerminationForms ref="contractterminationforms"></ContractTerminationForms>
            <ContractTypes ref="contracttypes"></ContractTypes>
            <ContractValueClusters ref="contractvalueclusters"></ContractValueClusters>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ContractService from '../../../../services/ContractService';
import ContractConfidentialities        from './modules/ContractConfidentialities';
import ContractExtensionTypes           from './modules/ContractExtensionTypes';
import ContractOccasions                from './modules/ContractOccasions';
import ContractRiskLevels               from './modules/ContractRiskLevels';
import ContractStates                   from './modules/ContractStates';
import ContractStrategicImportances     from './modules/ContractStrategicImportances';
import ContractSubjects                 from './modules/ContractSubjects';
import ContractTerminationForms         from './modules/ContractTerminationForms';
import ContractTypes                    from './modules/ContractTypes';
import ContractValueClusters            from './modules/ContractValueClusters';

export default {
    name: 'settings-list',
    components: {
            ContractConfidentialities, 
            ContractSubjects, 
            ContractExtensionTypes, 
            ContractOccasions, 
            ContractRiskLevels, 
            ContractStates, 
            ContractStrategicImportances,
            ContractTerminationForms,
            ContractTypes,
            ContractValueClusters
        },
    data() {
        return {
        }
    },
    created() {
        this.fetchContractConfidentialities();
        this.fetchContractExtensionTypes();
        this.fetchContractOccasions();
        this.fetchContractRiskLevels();
       this.fetchContractStates();
        this.fetchContractStrategicImportances();
        this.fetchContractSubjects();
        this.fetchContractTerminationForms();
       this.fetchContractTypes();
       this.fetchContractValueClusters();
    },
    methods: {
        ...mapActions({
            fetchContractConfidentialities: 'contracts/fetchContractConfidentialities',
            fetchContractExtensionTypes: 'contracts/fetchContractExtensionTypes',
            fetchContractOccasions: 'contracts/fetchContractOccasions',
            fetchContractRiskLevels: 'contracts/fetchContractRiskLevels',
            fetchContractStates: 'contracts/fetchContractStates',
            fetchContractStrategicImportances: 'contracts/fetchContractStrategicImportances',
            fetchContractSubjects: 'contracts/fetchContractSubjects',
            fetchContractTerminationForms: 'contracts/fetchContractTerminationForms',
            fetchContractTypes: 'contracts/fetchContractTypes',
            fetchContractValueClusters: 'contracts/fetchContractValueClusters',
//          deleteContractSubjects: 'projects/deleteContractSubjects'
        }),
/*        
        requestConfirmation(project) {
            this.projectContext = project;
            this.$refs.confirmationModal.show();
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteProject(this.projectContext.id)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("project.notify_delete_title"),
                        type: 'success',
                        text: this.$t("project.notify_delete_message")
                    });

                    this.projectContext = null;
                });
        },
*/
    },
    computed: {
        ...mapGetters({
            contractConfidentialities: 'contracts/contractConfidentialities',
            contractExtensionTypes: 'contracts/contractExtensionTypes',
            contractOccasions: 'contracts/contractOccasions',
            contractRiskLevels: 'contracts/contractRiskLevels',
            contractStates: 'contracts/contractStates',
            contractStrategicImportances: 'contracts/contractStrategicImportances',
            contractSubjects: 'contracts/contractSubjects',
            contractTerminationForms: 'contracts/contractTerminationForms',
            contractTypes: 'contracts/contractTypes',
            contractValueClusters: 'contracts/contractValueClusters',
            hasRole: 'auth/hasRole',
        }),
    }
};
</script>
