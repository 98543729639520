<template>
    <div class="home">
        <b-img class="mb-3" src="images/bild.png" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group class="mb-4" deck>
            <b-card title="Marcus Epping">
                <p class="card-text">
                    Einkaufsleitung indirektes Material<br/><br/>
                    <a href="mailto: marcus.epping@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 173 394 5908
                </p>
            </b-card>
            <b-card title="Stefan Franke">
                <p class="card-text">
                    Stellv. Einkaufsleitung<br/><br/>
                    <a href="mailto: stefan.franke@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 9123 185 157
                </p>
            </b-card>
            <b-card title="Stefan Franke">
                <p class="card-text">
                    Stellv. Einkaufsleitung<br/><br/>
                    <a href="mailto: stefan.franke@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 9123 185 157
                </p>
            </b-card>
        </b-card-group>
        <b-card-group class="mb-4" deck>
            <b-card title="Marcus Epping">
                <p class="card-text">
                    Einkaufsleitung indirektes Material<br/><br/>
                    <a href="mailto: marcus.epping@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 173 394 5908
                </p>
            </b-card>
            <b-card title="Stefan Franke">
                <p class="card-text">
                    Stellv. Einkaufsleitung<br/><br/>
                    <a href="mailto: stefan.franke@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 9123 185 157
                </p>
            </b-card>
            <b-card title="Stefan Franke">
                <p class="card-text">
                    Stellv. Einkaufsleitung<br/><br/>
                    <a href="mailto: stefan.franke@westfleisch.de">E-Mail senden</a><br/>
                    Tel. +49 9123 185 157
                </p>
            </b-card>
        </b-card-group>
        <b-card-group class="mb-4" deck>
            <b-card title="André Grotstabel">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: andre.grotstabel@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 173 250 1031
                </p>
            </b-card>
            <b-card title="Piet Ridderskamp">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: piet.ridderskamp@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 174 176 7921
                </p>
            </b-card>
            <b-card title="Piet Ridderskamp">
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen (Westfleisch-Gruppe)<br/><br/>
                    <a href="mailto: piet.ridderskamp@ebnerstolz.de">E-Mail senden</a><br/>
                    Tel. +49 174 176 7921
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>