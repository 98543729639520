<template>
    <b-modal size="lg" ref="createNewItemModal" hide-footer :title="$t(`risk.modal_title_editcountry`)">
        <b-container>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`countrymodal.country_iso_3166`)" label-for="country_iso_3166">
                        <b-form-input id="country_iso_3166" type="text" :state="!$v.currentItem.country_iso_3166.$error ? null : false" v-model="currentItem.country_iso_3166" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`countrymodal.country_name`)" label-for="country_name">
                        <b-form-input id="country_name" type="text" v-model="currentItem.country_name" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <b-button class="button-margin mt-3" variant="primary" block @click="createItem">{{ $t("risk.btn_safe") }}</b-button>
    </b-modal>
</template>
    <script>
    import CountryService from '../../../../../services/Riskmanagement/CountryService';
    import { helpers, required } from 'vuelidate/lib/validators';
    export default {
        name: 'country-modal',
        props: ['savedCallback'],
        data() {
            return {
                currentItemEditMode: false,
                currentItem: {}
            }
        },
        validations: {
            currentItem: {
                country_iso_3166:   {required},
                country_name:       {required},
            }
        },
        methods: {
            enterEditMode() {
                this.currentItemEditMode = true;
            },
            leaveEditMode() {
                this.currentItemEditMode = false;
            },
            showModal() {
                this.$v.$reset();
                this.currentItemEditMode = false;
                this.$refs.createNewItemModal.show();
            },
            hideModal() {
                this.$refs.createNewItemModal.hide();
                this.currentItem = {};
                this.$v.$reset();
            },
            setItem(Item) {
                this.currentItem = Item;
            },
            createItem() {
                this.$v.$touch();

                if(this.$v.$error) {
                    return;
                }

                this.$store.commit('loading/setLoading', true);

                if(!this.currentItemEditMode) {
                    CountryService.create(this.currentItem)
                        .then(response => {
                            this.$notify({
                                group: 'global',
                                title: this.$t("risk.notify_success_title_country"),
                                type: 'success',
                                text: this.$t("risk.notify_success_message_country")
                            });

                            this.$store.commit('loading/setLoading', false);

                            if(this.savedCallback){
                                this.savedCallback();
                            }

                            this.$refs.createNewItemModal.hide();
                            this.currentItem = {};
                        })
                        .catch(error => {
                            this.$store.commit('loading/setLoading', false);
                            this.$notify({
                                group: 'global',
                                title: this.$t("risk.notify_error_title_country"),
                                type: 'error',
                                text: error.message
                            });
                        });
                }else {
                    CountryService.update(this.currentItem.id, this.currentItem)
                        .then(response => {
                            this.$notify({
                                    group: 'global',
                                title: this.$t("risk.notify_correction_title_country"),
                                    type: 'success',
                                text: this.$t("risk.notify_correction_message_country")
                                });

                            if(this.savedCallback){
                                this.savedCallback();
                            }

                            this.$refs.createNewItemModal.hide();
                            this.currentItem = {};
                        })
                        .catch(error => {
                            this.$store.commit('loading/setLoading', false);
                            this.$notify({
                                group: 'global',
                                title: this.$t("risk.notify_correction_error_country"),
                                type: 'error',
                                text: error.message
                            });
                        });
                }
            }
        }
    }
    </script>
