<template>
<div class="card shadow">
    <div class="card-header">{{ $t("login.title") }}</div>
    <div class="card-body">
	<form>
        <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label text-md-right">{{ $t("login.email") }}</label>

            <div class="col-md-6">
                <input id="email" type="email" class="form-control" name="email" v-model="user.email" required autofocus>
            </div>
        </div>

        <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label text-md-right">{{ $t("login.password") }}</label>

            <div class="col-md-6">
                <input id="password" type="password" class="form-control" name="password" v-model="user.password" required>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 offset-md-4">
                <b-button class="button-margin" variant="primary" type="submit" @click.prevent="loginUser(user)">
                    {{ $t("login.button") }}
                </b-button>

            </div>
        </div>
	</form>
        <div class="form-group row mb-0">
            <div class="col-md-8 offset-md-4">
                <router-link to="/forgotpassword" href="#">{{ $t("login.forgotpassword") }}</router-link>
            </div>
        </div>
    </div>
</div>

</template>

<script>

import { mapActions } from 'vuex';
import Auth from '../../services/AuthService';

export default {
    name: 'login',
    data() {
        return {
            user: {
                email: '',
                password: ''
            }
        }
    },
    created() {
        Auth.getCsrfcookie();
    },
    methods: {
        ...mapActions({
            login: 'auth/login',
            authenticate: 'auth/authenticate'
        }),
        loginUser (user) {
            this.login(user)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("login.title"),
                        type: 'success',
                        text: this.$t("login.success")
                    });
                    if (this.$route.query.fromPath) {
                        this.$router.push({path: this.$route.query.fromPath});
                    } else {
                        this.$router.push({path: '/app/dashboard'});
                    }
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("login.title"),
                        type: 'error',
                        text: this.$t("login.error")
                    });
                });
        }
    }
};

</script>
