<template>
    <b-modal ref="fileDialog" hide-footer title="Hauptvertrag hochladen">
        <b-container>
            <b-form @submit.prevent="onFileUploadSubmit">
                <b-row>
                    <b-form-group label="Hauptdokument" label-for="contract-file">
                        <b-form-file id="contract-file" placeholder="Dokument wählen..." v-model="currentFile.file" :state="!$v.currentFile.file.$error ? null : false" accept/>
                    </b-form-group>
                </b-row>

                <b-row>
                    <b-button class="button-margin" type="submit"><b-icon icon="save" />Speichern</b-button>
                </b-row>

            </b-form>
        </b-container>
    </b-modal>
</template>
<script>
    import contractService from '../../../../../services/ContractService';
    import { transformToFormData } from '../../../../mixins/transformToFormData';
    import { helpers, required } from 'vuelidate/lib/validators';
    export default {
        name: 'create-contractfile-modal',
        props: ['savedCallback', 'contract'],
        mixins: [transformToFormData],
        data() {
            return {
                currentFile: {},
            }
        },
        validations: {
            currentFile: {
                file: { required },
            }
        },
        methods: {
            showModal() {
                this.$v.$reset();
                this.$refs.fileDialog.show();
            },
            hideModal() {
                this.$refs.fileDialog.hide();
                this.currentFile = {};
                this.$v.$reset();
            },
            onFileUploadSubmit() {
                this.$v.$touch();

                if(this.$v.$error) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabeprüfung',
                        type: 'error',
                        text: 'Eingabe unvollständig oder fehlerhaft'
                    });
                    return;
                }

                this.$store.commit('loading/setLoading', true);
                contractService.createContractFile(this.contract.id, this.transformToFormData(this.currentFile, [], 'PUT'))
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        this.$notify({
                                group: 'global',
                                title: 'Hauptdokument hochgeladen.',
                                type: 'success',
                                text: 'Das Hauptdokument wurde erfolgreich hochgeladen.'
                            });

                        this.$refs.fileDialog.hide();

                        this.currentFile = {};

                        if(this.savedCallback) {
                            this.savedCallback(response.data.contract_filename);
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);

                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Hochladen des Hauptdokumentes',
                            type: 'error',
                            text: error.message
                        });
                    })
            },
        },
    }
    </script>
