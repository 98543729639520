<template>
    <b-card title="Lieferantenrisiken" class="mb-3 shadow">

        <div class="table-scrollable">
            <b-table striped hover :items="supplierrisks" :fields="fields" responsive="sm">
                <template #cell(score)="data">
                    <div style="width: 10rem;">
                        <b-progress :max="score_max">
                            <b-progress-bar :value="data.item.questionnaire_score.score_value">
                            <span>{{ data.item.questionnaire_score.score_value }} / {{ score_max }}</span>
                            </b-progress-bar>
                        </b-progress>
                    </div>
                </template>
            </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'supplierrisktab',
    components: {},
    props: [],
    data() {
        return {
            supplierrisks: [],
            selectedYear: null,
            score_high: 2,
            score_middle: 4,
            score_low: 6,
            score_max: 50,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSupplierRisks();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                //{ key: 'ID', sortable: true },
                { key: 'year.year',                             label: 'Jahr',              sortable: true },
                { key: 'display_name',                          label: 'Lieferant',         sortable: true },
                { key: 'sector.sector_code_nace',               label: 'NACE',              sortable: true },
                { key: 'sector.sector_name',                    label: 'Branche',           sortable: true },
                { key: 'sector_score.score',                    label: 'Branchenrisiko',    sortable: true },
                { key: 'country.country_iso_3166',              label: 'ISO Code',          sortable: true },
                { key: 'country.country_name',                  label: 'Land',              sortable: true },
                { key: 'country_score.score',                   label: 'Länderrisiko',      sortable: true },
                { key: 'questionnaire_score.score',             label: 'Fragebogen Status', sortable: true },
                { key: 'questionnaire_score.score_value',       label: 'Fragebogen Score',  sortable: true },
                { key: 'score',                                 label: 'Scores',            sortable: true },
                { key: 'score',                                 label: 'Fragebogen Max',    sortable: true },
                //{ key: 'questionnaire_score.score',           label: 'Fragebogen',        sortable: true },
                //{ key: 'sector_risk_sum',                     label: 'Risiko Branche',    sortable: true },
            ];
        },
    },
}
</script>