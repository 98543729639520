<template>
    <b-modal size="lg" ref="createNewItemModal" hide-footer :title="$t(`createpositionmodal.title`)">
        <b-container>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_name`)" label-for="auctionitem-name">
                        <b-form-input id="auctionitem-name" type="text" :state="!$v.currentAuctionItem.name.$error ? null : false" v-model="currentAuctionItem.name" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_description`)" label-for="auctionitem-description">
                        <b-form-textarea id="auctionitem-description" type="text" v-model="currentAuctionItem.description" :rows="4" :max-rows="4" no-resize style="background-color: #fdfdfd"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_amount`)" label-for="auctionitem-amount">
                        <b-form-input id="auctionitem-amount" type="text" :state="!$v.currentAuctionItem.amount.$error ? null : false" v-model="currentAuctionItem.amount" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_unit`)" label-for="auctionitem-unit">
                        <b-form-input id="auctionitem-unit" type="text" :state="!$v.currentAuctionItem.unit.$error ? null : false" v-model="currentAuctionItem.unit" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_allowbids`)" label-for="auctionitem-allowbids">
                        <b-form-checkbox id="auctionitem-allowbids" v-model="currentAuctionItem.bids_allowed"></b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_priceunit`)" label-for="auctionitem-priceunit">
                        <b-form-input id="auctionitem-priceunit" type="text" :state="!$v.currentAuctionItem.price_unit.$error ? null : false" v-model="currentAuctionItem.price_unit" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_quantityfactor`)" label-for="auctionitem-quantityfactor">
                        <b-form-input id="auctionitem-quantityfactor" type="text" :state="!$v.currentAuctionItem.quantity_factor.$error ? null : false" v-model="currentAuctionItem.quantity_factor" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_lowestbid`)" label-for="auctionitem-lowestbid">
                        <b-form-input id="auctionitem-lowestbid" type="text" v-model="currentAuctionItem.lowest_bid" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_actualvalue`)" label-for="auctionitem-actualvalue">
                        <b-form-input id="auctionitem-actualvalue" type="text" v-model="currentAuctionItem.actual_value" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_periodofdemand`)" label-for="auctionitem-periodofdemand">
                            <b-form-input id="auctionitem-periodofdemand" type="text" v-model="currentAuctionItem.period_of_demand" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.auctionitem_commoditygroup`)" label-for="auctionitem-commoditygroup">
                            <b-form-input id="auctionitem-commoditygroup" type="text" v-model="currentAuctionItem.commodity_group" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <hr />
        <b-container>
            <h4>{{ $t("createpositionmodal.price_alert_settings") }}</h4>
            <b-row>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.price_alert_value_buyer`)" label-for="price-alert-value-buyer">
                        <b-form-input id="price-alert-value-buyer" type="text" v-model="currentAuctionItem.price_alert_value_buyer" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t(`createpositionmodal.price_alert_value_supplier`)" label-for="price-alert-value-buyer">
                            <b-form-input id="price-alert-value-buyer" type="text" v-model="currentAuctionItem.price_alert_value_supplier" style="background-color: #fdfdfd"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <b-button class="button-margin mt-3" variant="primary" block @click="createAuctionItem">{{ $t("createpositionmodal.button_safe") }}</b-button>
    </b-modal>
</template>
    <script>
    import numberHelper from '../../../../../helpers/numberHelper';
    import objectHelper from '../../../../../helpers/objectHelper';
    import callForBidsService from '../../../../../services/CallForBidsService';
    import { helpers, required } from 'vuelidate/lib/validators';
    export default {
        name: 'create-position-modal',
        props: ['savedCallback', 'auction'],
        data() {
            return {
                currentAuctionItemEditMode: false,
                currentAuctionItem: {}
            }
        },
        validations: {
            currentAuctionItem: {
                name: {
                    required
                },
                amount: {
                    required
                },
                unit: {
                    required
                },
                price_unit: {
                    required
                },
                quantity_factor: {
                    required
                },
                quantity_factor: {
                    required
                }
            }
        },
        methods: {
            enterEditMode() {
                this.currentAuctionItemEditMode = true;
            },
            leaveEditMode() {
                this.currentAuctionItemEditMode = false;
            },
            showModal() {
                this.$v.$reset();
                this.currentAuctionItemEditMode = false;
                this.$refs.createNewItemModal.show();
            },
            hideModal() {
                this.$refs.createNewItemModal.hide();
                this.currentAuctionItem = {};
                this.$v.$reset();
            },
            setAuctionItem(auctionItem) {
                this.currentAuctionItem = auctionItem;
            },
            createAuctionItem() {
                this.$v.$touch();

                if(this.$v.$error) {
                    return;
                }

                this.$store.commit('loading/setLoading', true);

                var itemCopy = objectHelper.copy(this.currentAuctionItem);
                itemCopy.auction_id = this.auction.id;
                itemCopy.actual_value = itemCopy.actual_value ? numberHelper.toCents(itemCopy.actual_value, this.auction.precision) : undefined;
                itemCopy.price_alert_value_buyer = itemCopy.price_alert_value_buyer ? numberHelper.toCents(itemCopy.price_alert_value_buyer, this.auction.precision) : undefined;
                itemCopy.price_alert_value_supplier = itemCopy.price_alert_value_supplier ? numberHelper.toCents(itemCopy.price_alert_value_supplier, this.auction.precision) : undefined;
                itemCopy.lowest_bid = itemCopy.lowest_bid ? numberHelper.toCents(itemCopy.lowest_bid, this.auction.precision) : undefined;

                if(!this.currentAuctionItemEditMode) {
                    callForBidsService.createAuctionItem(itemCopy)
                        .then(response => {
                            this.$notify({
                                group: 'global',
                                title: this.$t("createpositionmodal.notify_success_title"),
                                type: 'success',
                                text: this.$t("createpositionmodal.notify_success_message")
                            });

                            this.$store.commit('loading/setLoading', false);

                            if(this.savedCallback){
                                this.savedCallback();
                            }

                            this.$refs.createNewItemModal.hide();
                            this.currentAuctionItem = {};
                        })
                        .catch(error => {
                            this.$store.commit('loading/setLoading', false);
                            this.$notify({
                                group: 'global',
                                title: this.$t("createpositionmodal.notify_error_title"),
                                type: 'error',
                                text: error.message
                            });
                        });
                }else {
                    callForBidsService.updateAuctionItem(itemCopy)
                        .then(response => {
                            this.$notify({
                                    group: 'global',
                                title: this.$t("createpositionmodal.notify_correction_title"),
                                    type: 'success',
                                text: this.$t("createpositionmodal.notify_correction_message")
                                });

                            if(this.savedCallback){
                                this.savedCallback();
                            }

                            this.$refs.createNewItemModal.hide();
                            this.currentAuctionItem = {};
                        })
                        .catch(error => {
                            this.$store.commit('loading/setLoading', false);
                            this.$notify({
                                group: 'global',
                                title: this.$t("createpositionmodal.notify_correction_error"),
                                type: 'error',
                                text: error.message
                            });
                        });
                }
            }
        }
    }
    </script>
