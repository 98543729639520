<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            <router-link :to="'/app/contracts'" href="#">{{ $t("contract.maintitle_create") }} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ contract.contract_title }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-tabs>
                    <b-tab title="Vertragsstammdaten" active>
                        <b-card-group deck>
                            <b-card title="Grunddaten" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Vertragsnummer" label-for="contr-number">
                                            <b-form-input id="contr-number" type="text" v-model="contract.contract_number"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertragstitel" label-for="contr-title">
                                            <b-form-input id="contr-title" type="text" v-model="contract.contract_title" :state="!$v.contract.contract_title.$error ? null : false"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertragsbeginn" label-for="contr-starts">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_startdate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="contr-starts" :state="!$v.contract.contract_startdate.$error ? null : false" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>    

                                    <b-row><b-col>
                                        <b-form-group label="Vertragsende" label-for="contr-ends">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_enddate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="contr-ends" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertragswährung" label-for="contr-currency">
                                            <b-form-input id="contr-currency" type="text" v-model="contract.contract_currency" :state="!$v.contract.contract_currency.$error ? null : false"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertragswert" label-for="contr-value">
                                            <b-form-input id="contr-value" type="text" v-model="contract.contract_value" :state="!$v.contract.contract_value.$error ? null : false"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                    <b-row><b-col>
                                        <b-form-group label="Wertcluster" label-for="contr-value-cluster">
                                            <b-form-select text-field="contract_value_cluster" value-field="id" :options="contractValueClusters" v-model="contract.contract_value_cluster_id" :state="!$v.contract.contract_value_cluster_id.$error ? null : false"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                            <b-card title="Fortführung und Beendigung" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Verlängerungsart" label-for="contr-extension-type">
                                            <b-form-select text-field="contract_extension_type" value-field="id" :options="contractExtensionTypes" v-model="contract.contract_extension_type_id" ></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Verlängerungsdauer" label-for="contr-extension-period">
                                            <b-form-input id="contr-extension-period" type="text" v-model="contract.contract_extension_period"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                    <b-row><b-col>
                                        <b-form-group label="Kündigungsfrist" label-for="contr-period-of-notice">
                                            <b-form-input id="contr-period-of-notice" type="text" v-model="contract.contract_period_of_notice"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Kündigungsform" label-for="contr-termination-form">
                                            <b-form-select text-field="contract_termination_form" value-field="id" :options="contractTerminationForms" v-model="contract.contract_termination_form_id"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Frühester Kündigungstermin" label-for="earliest-termination-date">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_earliest_termination_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="earliest-termination-date" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertrag gekündigt am ..." label-for="contr-terminated-at">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_terminated_at" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="contr-terminated-at" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertrag gekündigt zum ..." label-for="contr-terminated-on">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_terminated_on" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="contr-terminated-on" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Gekündigt durch ..." label-for="contr-termination-by">
                                            <b-form-input id="contr-termination-by" type="text" v-model="contract.contract_termination_by"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                    
                                    <b-row><b-col>
                                        <b-form-group label="Kündigungsdatum" label-for="termination-date">
                                            <b-form-datepicker today-variant="info" dropdown label-help="Navigation mit Pfeiltasten" v-model="contract.contract_termination_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="termination-date" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                            <b-card title="Vertragssteuerung" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Vertragsstatus" label-for="contr-state">
                                            <b-form-select text-field="contract_state" value-field="id" :options="contractStates" v-model="contract.contract_state_id" :state="!$v.contract.contract_state_id.$error ? null : false"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
    
                                    <b-row><b-col>
                                        <b-form-group label="Vertragsanlass" label-for="contr-occasion">
                                            <b-form-select text-field="contract_occasion" value-field="id" :options="contractOccasions" v-model="contract.contract_occasion_id" :state="!$v.contract.contract_occasion_id.$error ? null : false"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
    
                                    <b-row><b-col>
                                        <b-form-group label="Vertraulichkeit" label-for="contr-confidentiality">
                                            <b-form-select text-field="contract_confidentiality" value-field="id" :options="contractConfidentialities" v-model="contract.contract_confidentiality_id"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
    
                                    <b-row><b-col>
                                        <b-form-group label="Vertragstyp" label-for="contr-type">
                                            <b-form-select text-field="contract_type" value-field="id" :options="contractTypes" v-model="contract.contract_type_id" :state="!$v.contract.contract_type_id.$error ? null : false"></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
                                    
                                    <b-row><b-col>
                                        <b-form-group label="Risikostufe" label-for="contr-risk-level">
                                            <b-form-select text-field="contract_risk_level" value-field="id" :options="contractRiskLevels" v-model="contract.contract_risk_level_id" ></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
                                    
                                    <b-row><b-col>
                                        <b-form-group label="Strategische Bedeutung" label-for="contr-strategic-importance">
                                            <b-form-select text-field="contract_strategic_importance" value-field="id" :options="contractStrategicImportances" v-model="contract.contract_strategic_importance_id" ></b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
                                    <br/>
    
                                </b-card-text>
                            </b-card> 
                            <b-card title="Konditionen" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Zahlungsbedingungen" label-for="contr-payment-conditions">
                                        <b-form-input id="contr-payment-conditions" type="text" v-model="contract.payment_conditions"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                                <b-row><b-col>
                                    <b-form-group label="Lieferbedingungen" label-for="contr-delivery-conditions">
                                        <b-form-input id="contr-delivery-conditions" type="text" v-model="contract.delivery_conditions"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                                <b-row><b-col>
                                    <b-form-group label="sonst. Konditionen" label-for="conditions-other">
                                        <b-form-input id="conditions-other" type="text" v-model="contract.conditions_other"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                                
                                <b-row><b-col>
                                    <b-form-group label="Konditionen Erläuterungen" label-for="conditions-description">
                                        <b-textarea id="conditions-description" type="text" v-model="contract.conditions_description"></b-textarea>
                                    </b-form-group>
                                </b-col></b-row>
                                <b-row><b-col>
                                    <b-form-group>
                                        <b-form-checkbox v-model="contract.bonus_annual"> Jahresbonus</b-form-checkbox>
                                    </b-form-group>
                                    <div v-if="contract.bonus_annual === true">
                                        <b-form-group class="ml-4" label="Bonusberechnung" label-for="bonus-annual-basis">
                                            <b-textarea id="bonus-annual-basis" type="text" :rows="4" v-model="contract.bonus_annual_basis"></b-textarea>
                                        </b-form-group>
                                    </div>
                                </b-col></b-row>
                                <b-row><b-col>
                                    <b-form-group>
                                        <b-form-checkbox v-model="contract.price_adjustment_clause"> Preisgleitklausel</b-form-checkbox>
                                    </b-form-group>
                                    <div v-if="contract.price_adjustment_clause === true">
                                        <b-form-group class="ml-4" label="Beschreibung Preisgleitklausel" label-for="price-adjustment-clause-description">
                                            <b-textarea id="price-adjustment-clause-description" type="text" :rows="4" v-model="contract.price_adjustment_clause_description"></b-textarea>
                                        </b-form-group>
                                    </div>
                                </b-col></b-row>
                            </b-card>                                                       
                        </b-card-group>

                        <b-card-group deck>
                            <b-card title="Prüfung und Genehmigung" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group>
                                        <b-form-checkbox v-model="contract.contract_reviewed"> Vertrag geprüft</b-form-checkbox>
                                    </b-form-group>
                                    <div v-if="contract.contract_reviewed === true">
                                        <b-form-group label="Geprüft durch ..." label-for="contr-reviewed-by">
                                            <b-form-input id="contr-reviewed-by" type="text" v-model="contract.contract_reviewed_by"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </b-col></b-row>
                                <b-row><b-col>
                                    <b-form-group>
                                        <b-form-checkbox v-model="contract.contract_approved"> Vertrag genehmigt</b-form-checkbox>
                                    </b-form-group>
                                    <div v-if="contract.contract_approved === true">
                                        <b-form-group label="Genehmigt durch ... (Stufe 1)" label-for="contr-approved-by-1">
                                            <b-form-input id="contr-approved-by-1" type="text" v-model="contract.contract_approved_by_1"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Genehmigt durch ... (Stufe 2)" label-for="contr-approved-by-2">
                                            <b-form-input id="contr-approved-by-1" type="text" v-model="contract.contract_approved_by_2"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Genehmigt durch... (Stufe 3)" label-for="contr-approved-by-3">
                                            <b-form-input id="contr-approved-by-1" type="text" v-model="contract.contract_approved_by_3"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </b-col></b-row>
                            </b-card>
                            <b-card title="Vertragspartner und Kontakte extern" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Partnerrolle extern" label-for="contr-extern-partner-role">
                                            <b-form-select v-model="contract.contract_extern_partner_role">
                                                <option value="0">Kreditor</option>
                                                <option value="1">Debitor</option>
                                                <option value="2">Sonstige</option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col></b-row>
                                    
                                    <b-row><b-col>
                                        <b-form-group label="Vertragspartner" label-for="contr-extern-partner">
                                            <b-form-input id="contr-extern-partner" type="text" v-model="contract.contract_extern_partner"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vertragspartner Nummer" label-for="contr-extern-partner-nr">
                                            <b-form-input id="contr-extern-partner-nr" type="text" v-model="contract.contract_extern_partner_number"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Verantwortlich extern" label-for="contr-extern-responsible">
                                            <b-form-input id="contr-extern-responsible" type="text" v-model="contract.contract_extern_responsible"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Fachlicher Ansprechpartner extern" label-for="contr-extern-contact-person">
                                            <b-form-input id="contr-extern-contact-person" type="text" v-model="contract.contract_extern_contact_person"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Adresse extern" label-for="contr-extern-adress">
                                            <b-form-input id="contr-extern-adress" type="text" v-model="contract.contract_extern_adress"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="E-mail extern" label-for="contr-extern-email">
                                            <b-form-input id="contr-extern-email" type="text" v-model="contract.contract_extern_email"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                </b-card-text>
                            </b-card>
                            <b-card title="Vertragspartner und Kontakte intern" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Vertragspartner Gesellschaft intern" label-for="contr-company">
                                        <b-form-input id="contr-company" type="text" v-model="contract.company"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                                
                                <b-row><b-col>
                                    <b-form-group label="Abteilung intern" label-for="contr-department">
                                        <b-form-input id="contr-department" type="text" v-model="contract.contract_department" :state="!$v.contract.contract_department.$error ? null : false"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
    
                                <b-row><b-col>
                                    <b-form-group label="Ansprechpartner intern" label-for="contr-intern-contact-person">
                                        <b-form-input id="contr-intern-contact-person" type="text" v-model="contract.contract_intern_contact_person"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group label="Verantwortlich Leitung" label-for="contr-intern-management">
                                        <b-form-input id="contr-intern-management" type="text" v-model="contract.contract_intern_management"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group label="Fachlicher Ansprechpartner" label-for="contr-intern-responsible">
                                        <b-form-input id="contr-intern-responsible" type="text" v-model="contract.contract_intern_responsible"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                            </b-card>
                        </b-card-group>
                        
                        <b-card-group deck>


                        </b-card-group>
                        <b-card-group deck>

                        </b-card-group>
                    </b-tab>

                    <b-tab title="Dokumente" :disabled="!editMode">
                        
                        <b-card-group deck>
                            <b-card title="Hauptvertrag" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row class="my-1" v-if="contract.contract_filename">
                                            <b-col sm="3"><label>Hinterlegter Hauptvertrag:</label></b-col>
                                            <b-col sm="9">{{contract.contract_filename}}</b-col>
                                    </b-row>
                                    <b-row class="my-1" v-if="contract.contract_filename">
                                        <b-col>
                                            <b-link class="m-1" href="#" @click="requestConfirmationDeleteContractFile()" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click="downloadContractFile()"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                        </b-col>
                                    </b-row>
                                    
                                    <b-row class="my-1" v-if="!contract.contract_filename">
                                        <b-col>Kein Hauptvertrag hinterlegt.</b-col>
                                    </b-row>
                                    <b-row class="my-1">
                                        <b-col><b-button class="button-margin" variant="primary" @click="showContractFileForm"><b-icon icon="file-earmark-arrow-up" />Hauptvertrag hochladen</b-button></b-col>
                                    </b-row>

                                </b-card-text>
                            </b-card>
                        </b-card-group>
                        
                        <b-card-group deck>
                            <b-card title="Anlagen" class="mb-4 shadow">
                                <b-card-text>
                                    <div class="table-scrollable">
                                        <b-table striped hover :items="contract.appendices" :fields="appendixFields">
                                            <template #cell(actions)="data">
                                                <b-link class="m-1" href="#" @click="requestConfirmationDeleteAppendix(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                                <b-link class="m-1" href="#" @click="downloadAppendix(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                                <b-link class="m-1" href="#" @click="editAppendix(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                            </template>
                                        </b-table>
                                    </div>
                                    <b-button class="button-margin" variant="primary" @click="showAppendixForm"><b-icon icon="file-earmark-arrow-up" />Anlage zufügen</b-button>
                                </b-card-text>
                            </b-card>
                        </b-card-group>

                        <b-modal ref="confirmationModalContractFile" hide-footer :title="'Hauptdokument löschen'">
                            <div class="d-block text-center">
                                <p>Möchten Sie das Hauptdokument <strong>'{{contract.contract_filename}}'</strong> wirklich löschen?</p>
                            </div>
                            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDeleteContractFile">Hauptdokument Löschen</b-button>
                        </b-modal>

                        <b-modal ref="confirmationModalAppendix" hide-footer :title="'Anlage löschen'">
                            <div class="d-block text-center">
                                <p v-if="appendixContext">Möchten Sie die Anlage <strong>'{{appendixContext.appendix_name}}'</strong> wirklich löschen?</p>
                            </div>
                            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDeleteAppendix">Anlage Löschen</b-button>
                        </b-modal>
                    </b-tab>

                    <b-tab title="Notizen">
                        
                        <b-card-group deck>
                            <b-card title="Beschreibung und Suchbegriffe" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Kostengruppe" label-for="contr-subject">
                                        <b-form-select text-field="contract_subject" value-field="id" :options="contractSubjects" v-model="contract.contract_subject_id" :state="!$v.contract.contract_subject_id.$error ? null : false"></b-form-select>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group label="Notizen zum Vertrag" label-for="contract-notes">
                                        <b-form-textarea id="contract-notes" placeholder="Ausführliche Textbeschreibung zum Vertrag..." v-model="contract.contract_notes" rows="6" />
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group label="Schlüsselbegriffe" label-for="contract-keywords">
                                        <b-form-textarea id="contract-keywords" placeholder="Einzelne Suchworte unter denen dieser Vertrag gefunden werden soll..." v-model="contract.contract_keywords" rows="4" />
                                    </b-form-group>
                                </b-col></b-row>
                            </b-card>
                            <b-card title="Alternative Bezugsquellen" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Alternativlieferanten" label-for="alternative-suppliers">
                                        <b-textarea id="alternative-suppliers" type="text" :rows="20" v-model="contract.alternative_suppliers"></b-textarea>
                                    </b-form-group>
                                </b-col></b-row>
                            </b-card>
                            <b-card title="Vertragsablage" class="mb-4 shadow">
                                <b-row><b-col>
                                    <b-form-group label="Physischer Ablageort" label-for="contract-storage-location">
                                        <b-form-textarea id="contract-storage-location" placeholder="Gebäude, Raumnummer, Schrank, Aktennummer, Mitarbeiter, ..." v-model="contract.contract_physical_location" rows="2" />
                                    </b-form-group>
                                </b-col></b-row>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Termine und Erinnerungen">
                        <b-card-group deck>
                            <b-card title="Erinnerungen und Wiedervorlagen" class="mb-4 shadow">
                                <br/>
                                <b-button class="button-margin" variant="primary" @click="showReminderForm"><b-icon icon="bell" />Wiedervorlage zufügen</b-button>
                                <div class="table-scrollable">
                                    <b-table striped hover :items="contract.reminders" :fields="reminderFields">
                                        <template #cell(actions)="data">
                                            <b-link class="m-1" href="#" @click="requestConfirmationDeleteReminder(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                            <b-link class="m-1" href="#" @click="editReminder(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                        </template>
                                    </b-table>
                                </div>

                                <b-modal ref="confirmationModalReminder" hide-footer :title="'Wiedervorlage löschen'">
                                    <div class="d-block text-center">
                                        <p v-if="reminderContext">Möchten Sie diese Wiedervorlage zum Thema: <strong>'{{reminderContext.resubmission_reason}}'</strong> wirklich entfernen?</p>
                                    </div>
                                    <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDeleteReminder">Wiedervorlage entfernen</b-button>
                                </b-modal>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Benutzer oder Benutzergruppe">
                        
                        <b-card-group deck>
                            <b-card title="Freigegebene Benutzer" class="mb-4 shadow">
                                <b-row><b-col>
                                    <div class="table-scrollable">
                                        <b-table striped hover :items="contract.users" :fields="userFields">
                                            <template #cell(actions)="data">
                                                <b-link class="m-1" href="#"  @click.prevent="requestConfirmationDeleteUser(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                            </template>
                                        </b-table>
                                    </div>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Benutzer" label-for="user-select">
                                            <b-form-select id="user-select" text-field="name" value-field="id" :options="users" v-model="selectedUser"></b-form-select>
                                        </b-form-group>
                                        
                                        <b-button class="button-margin" variant="primary" @click="addUserToContract"><b-icon icon="person-plus" />Benutzer hinzufügen</b-button>
                                    </b-col></b-row>

                                    <b-modal ref="confirmationModalUser" hide-footer title="Benutzer entfernen">
                                        <div class="d-block text-center">
                                            <p v-if="userContext">Möchten Sie den Benutzer <strong>'{{userContext.name}}'</strong> aus der Vertragszuordnung entfernen?</p>
                                        </div>
                                        <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDeleteUser"><b-icon icon="person-dash" />Benutzer entfernen</b-button>
                                    </b-modal>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Beziehungen">
                        
                        <b-card-group deck>
                            <b-card title="Vertragszuordnung" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Division" label-for="division">
                                            <b-form-input id="division" type="text" v-model="contract.division"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Einkaufsorganisation" label-for="purchasing-org">
                                            <b-form-input id="purchasing-org" type="text" v-model="contract.purchasing_organisation"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Einäufergruppe" label-for="purchasing-group">
                                            <b-form-input id="purchasing-group" type="text" v-model="contract.purchasing_group"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Standort" label-for="comp-location">
                                            <b-form-input id="comp-location" type="text" v-model="contract.company_location"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Profit Center" label-for="profit-center">
                                            <b-form-input id="profit-center" type="text" v-model="contract.profit_center"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Cost Center" label-for="cost-center">
                                            <b-form-input id="cost-center" type="text" v-model="contract.cost_center"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Kostenträger" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group label="Equipment" label-for="equipment-number">
                                            <b-form-input id="equipment-number" type="text" v-model="contract.equipment_number"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Investition" label-for="invest-number">
                                            <b-form-input id="invest-number" type="text" v-model="contract.investment_number"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                        
                        <b-card-group deck>
                            <b-card title="Zuordnung zum Vergabeprozess" class="mb-4 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <div class="table-scrollable">
                                            <b-table striped hover :items="contract.auctions" :fields="auctionFields">
                                                <template #cell(actions)="data">
                                                    <b-link class="m-1" href="#"  @click.prevent="requestConfirmationDeleteAuction(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group label="Vergabe" label-for="auction-select">
                                            <b-form-select id="auction-select" text-field="display_name" placeholder="Welche Vergabe ist Grundlage für diesen Vertrag?" value-field="id" :options="auctions" v-model="selectedAuction"></b-form-select>
                                        </b-form-group>
                                        <b-button class="button-margin" variant="primary" @click="addAuctionToContract"><b-icon icon="plus-circle" />Vergabe hinzufügen</b-button>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                        </b-card-group>

                        <b-modal ref="confirmationModalAuction" hide-footer :title="'Vergabe entfernen'">
                            <div class="d-block text-center">
                                <p v-if="auctionContext">Möchten Sie die Vergabe <strong>'{{auctionContext.title}}'</strong> aus dieser Vertragszuordnung wirklich entfernen?</p>
                            </div>
                            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDeleteAuction">Vergabe entfernen</b-button>
                        </b-modal>
                    </b-tab>
                </b-tabs>
                
                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />Speichern</b-button>
            </b-form>
        </div>

        <CreateAppendixModal ref="createAppendixModal" :savedCallback="reloadAppendices" :contract="contract"/>
        <CreateContractFileModal ref="createContractFileModal" :savedCallback="reloadContractFile" :contract="contract"/>
        <CreateReminderModal ref="createReminderModal" :savedCallback="reloadReminders" :contract="contract"/>

    </div>
</template>

<script>
import {_} from 'vue-underscore';

import CreateAppendixModal from './modules/CreateAppendixModal';
import CreateContractFileModal from './modules/CreateContractFileModal';
import CreateReminderModal from './modules/CreateReminderModal';
import {mapGetters, mapActions} from 'vuex';
import contractService from '../../../../services/ContractService';
import {helpers, required, numeric} from 'vuelidate/lib/validators';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'contract-createedit',
    mixins: [dateHelper],
    components: {CreateAppendixModal, CreateContractFileModal, CreateReminderModal},
    data() {
        return {
            editMode: false,
            excelFile: null,
            doneLoading: false,
/*
            userSearch: '',
            availableUsers:[],
            selectedUser: [],
*/
            reminderToDelete: {},
            appendixToDelete: {},
            contract: {
                contract_number: '',
                contract_title: '',
                contract_reviewed: 0,
                contract_approved: 0,
                bonus_annual: 0,
                price_adjustment_clause: 0,
                appendices: [],
                reminders: [],
                users: [],
                auctions: [],
                contract_currency: 'Euro',
            },
            selectedUser: '',
            selectedAuction: '',
            selectedUser: '',
            contractFileContext: '',
            appendixContext: '',
            reminderContext: '',
            userContext: '',
            auctionContext: '',
            currentFile: {
                name: '',
            },
            editingFile: false,
        }
    },
    created() {
        return this.fetchUsers()
            .then(() => {
                this.$store.commit('loading/setLoading', false);

                return this.fetchAuctions()
                .then(() => {
                    this.$store.commit('loading/setLoading', false);

                    return this.fetchSelectionLists()
                    .then(() => {
                        this.$store.commit('loading/setLoading', false);

                        if(this.$route.params.id) {
                            this.$store.commit('loading/setLoading', true);
                            contractService.fetch(this.$route.params.id)
                                .then(data => {
                                    this.contract = data.data;
                                    this.editMode = true;
                                    this.doneLoading = true;
                                    this.$store.commit('loading/setLoading', false);
                                });
                        }
                        else {
                            this.doneLoading = true;
                        }
                    });
                });
            });

    },
    validations: {
        contract: {
            contract_title:                 { required },
            //contract_number:                { required },

            contract_startdate:             { required },
            //contract_enddate:               { required },
            //contract_period_of_notice:      { required },
            //contract_termination_form:      { required },
            //contract_extension_type:        { required },
            //contract_extension_period:      { required },

            //contract_reviewed:              { required },
            //contract_approved:              { required },
            contract_type_id:               { required },
            //contract_confidentiality_id:    { required },
            contract_value_cluster_id:      { required },
            contract_value:                 { required },
            contract_currency:              { required },
            contract_department:            { required },
            contract_subject_id:            { required },
            contract_occasion_id:           { required },
            contract_state_id:              { required },
            //company:                        { required },
            //contract_extern_contact_person: { required },
            //contract_intern_contact_person: { required }
        }
    },
    methods: {
        ...mapActions({
            fetchAuctions: 'contracts/fetchAuctions',
            fetchUsers: 'contracts/fetchUsers',
            fetchSelectionLists: 'contracts/fetchSelectionLists'
        }),
        onSubmit() {
            this.$v.$touch();

            if(this.$v.$error) {
                this.$notify({
                    group: 'global',
                    title: 'Eingabeprüfung',
                    type: 'error',
                    text: 'Eingabe unvollständig oder fehlerhaft'
                });
                return;
            }
            if(!this.editMode) {
                this.create();
            }
            else {
                this.update();
            }
        },
        create() {
            /*this.auction.organizer = this.selectedOrganizer;*/

            this.$store.commit('loading/setLoading', true);
            contractService.create(this.contract)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if(response.status == 201) {
                        this.contract = response.data.data;
                        this.editMode = true;

                        this.$notify({
                            group: 'global',
                            title: 'Vertrag erstellt.',
                            type: 'success',
                            text: 'Der Vertrag wurde erfolgreich angelegt.'
                        });

                    } else {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Erstellen des Vertrags',
                            type: 'error',
                            text: response.data.message
                        });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: 'Fehler beim Erstellen des Vertrags',
                        type: 'error',
                        text: error.message
                    });
                });
        },
        update() {
            /*this.auction.organizer = this.selectedOrganizer*/

            this.$store.commit('loading/setLoading', true);

            contractService.update(this.contract.id, this.contract)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);

                    if(response.status == 200) {
                        //this.contract = response.data;
                        this.editMode = true;

                        this.$notify({
                            group: 'global',
                            title: 'Vertrag gespeichert.',
                            type: 'success',
                            text: 'Der Vertrag wurde erfolgreich gespeichert.'
                        });
                    }
                    else {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Speichern des Vertrags',
                            type: 'error',
                            text: (response.data === undefined) ? response.message : response.data.message,
                        });
                    }
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);

                    this.$notify({
                        group: 'global',
                        title: 'Fehler beim Speichern des Vertrags',
                        type: 'error',
                        text: error.message
                    });
                });
        },
        searchAuction(query) {
            contractService.searchAuction(query)
                .then(response => {
                    this.availableAuctions = response.data;
                });
        },
        addAuctionToContract() {
            if(this.selectedAuction) {
                this.$store.commit('loading/setLoading', true);

                contractService.assignAuction(this.contract.id, this.selectedAuction)
                    .then(response => {
                        this.selectedAuction = null;
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {

                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Hinzufügen der Vergabe.',
                                type: 'error',
                                text: response.data.message
                            });

                            return;
                        }
                        this.fetchAssignedAuctions();
                        this.auctionSearch = '';
                        this.selectedAuction = null;
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Hinzufügen der Vergabe.',
                            type: 'error',
                            text: error.message
                        });
                    });
            }
        },
        requestConfirmationDeleteAuction(auction) {
            this.auctionContext = auction;
            this.$refs.confirmationModalAuction.show();
        },
        executeDeleteAuction() {
            this.$refs.confirmationModalAuction.hide();
            this.$store.commit('loading/setLoading', true);
            contractService.unassignAuction(this.contract.id, this.auctionContext.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Entfernen der Vergabe.',
                            type: 'error',
                            text: response.data.message
                        });
                    }

                    this.auctionContext = null;
                    this.fetchAssignedAuctions();
                });
        },
        fetchAssignedAuctions() {
            return contractService.fetchAuctions(this.contract.id)
                .then(response => {
                    this.contract.auctions = response.data;
                });
        },
        addUserToContract() {
            if(this.selectedUser) {
                this.$store.commit('loading/setLoading', true);

                contractService.addUser(this.contract.id, this.selectedUser)
                    .then(response => {
                        this.selectedUser = null;
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {

                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Hinzufügen des Benutzers.',
                                type: 'error',
                                text: response.data.message
                            });

                            return;
                        }
                        this.fetchAssignedUsers();
                        this.userSearch = '';
                        this.selectedUser = null;
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Hinzufügen des Benutzers.',
                            type: 'error',
                            text: error.message
                        });
                    });
            }
        },
        requestConfirmationDeleteUser(user) {
            this.userContext = user;
            this.$refs.confirmationModalUser.show();
        },
        executeDeleteUser() {
            this.$refs.confirmationModalUser.hide();
            this.$store.commit('loading/setLoading', true);
            contractService.unassignUser(this.contract.id, this.userContext.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Entfernen der Benutzerzuordnung.',
                            type: 'error',
                            text: response.data.message
                        });
                    }

                    this.userContext = null;
                    this.fetchAssignedUsers();
                });
        },
        fetchAssignedUsers() {
            return contractService.fetchUsers(this.contract.id)
                .then(response => {
                    this.contract.users = response.data;
                });
        },
        showContractFileForm() {
            this.$refs.createContractFileModal.showModal();
        },
        showAppendixForm() {
            this.$refs.createAppendixModal.showModal();
        },
        showReminderForm() {
            this.$refs.createReminderModal.showModal();
        },
        reloadContractFile(contract_filename) {
            this.contract.contract_filename = contract_filename;
        },
        reloadAppendices() {
            this.$store.commit('loading/setLoading', true);
            contractService.listAppendices(this.contract.id)
                .then(response => {
                    this.contract.appendices = response.data;
                    this.$store.commit('loading/setLoading', false);
                });
        },
        reloadReminders() {
            this.$store.commit('loading/setLoading', true);
            contractService.listReminders(this.contract.id)
                .then(response => {
                    this.contract.reminders = response.data;
                    this.$store.commit('loading/setLoading', false);
                });
        },
        requestConfirmationDeleteContractFile() {
            this.$refs.confirmationModalContractFile.show();
        },
        requestConfirmationDeleteAppendix(appendix) {
            this.appendixContext = appendix;
            this.$refs.confirmationModalAppendix.show();
        },
        requestConfirmationDeleteReminder(reminder) {
            this.reminderContext = reminder;
            this.$refs.confirmationModalReminder.show();
        },
        executeDeleteContractFile() {
            this.$refs.confirmationModalContractFile.hide();
            this.$store.commit('loading/setLoading', true);
            contractService.deleteContractFile(this.contract.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(response.data.success) {
                        this.contract.contract_filename = null;
                        this.$notify({
                            group: 'global',
                            title: 'Hauptdokument gelöscht.',
                            type: 'success',
                            text: 'Das Hauptdokument wurde gelöscht.'
                        });
                    } else {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Löschen des Hauptdokumentes.',
                            type: 'error',
                            text: response.data.message
                        });
                    }
                });
        },
        executeDeleteAppendix() {
            this.$refs.confirmationModalAppendix.hide();
            this.$store.commit('loading/setLoading', true);
            contractService.deleteAppendix(this.contract.id, this.appendixContext.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Löschen der Anlage.',
                            type: 'error',
                            text: response.data.message
                        });
                    }

                    this.appendixContext = null;
                    this.reloadAppendices();
                });
        },
        executeDeleteReminder() {
            this.$refs.confirmationModalReminder.hide();
            this.$store.commit('loading/setLoading', true);
            contractService.deleteReminder(this.contract.id, this.reminderContext.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Fehler beim Löschen der Wiedervorlage.',
                            type: 'error',
                            text: response.data.message
                        });
                    }

                    this.reminderContext = null;
                    this.reloadReminders();
                });
        },
        downloadContractFile() {
            this.$store.commit('loading/setLoading', true);
            contractService.downloadContractFile(this.contract.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    const url = window.URL.createObjectURL(new Blob([response], {type: this.contract.contract_mimetype}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.contract.contract_filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadAppendix(item) {
            this.$store.commit('loading/setLoading', true);
            contractService.downloadAppendix(this.contract.id, item.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.appendix_filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        // TODO: Warum steht hier nicht auch "enterEditMode(item)" Welche Funktion hat das item in der Klammer?
        editAppendix(item) {
            this.$refs.createAppendixModal.showModal();
            this.$refs.createAppendixModal.setFileItem(JSON.parse(JSON.stringify(item)));
            this.$refs.createAppendixModal.enterEditMode();
        },
        editReminder(item) {
            this.$refs.createReminderModal.showModal();
            this.$refs.createReminderModal.enterEditMode(item);
        },
    },
    computed: {
        ...mapGetters({
            auctions: 'contracts/auctions',
            users: 'contracts/users',
            contracts: 'contracts/contracts',
            hasRole: 'auth/hasRole',
            appendixTypes: 'contracts/appendixTypes',
            contractConfidentialities: 'contracts/contractConfidentialities',
            contractExtensionTypes: 'contracts/contractExtensionTypes',
            contractOccasions: 'contracts/contractOccasions',
            contractRiskLevels: 'contracts/contractRiskLevels',
            contractStates: 'contracts/contractStates',
            contractStrategicImportances: 'contracts/contractStrategicImportances',
            contractSubjects: 'contracts/contractSubjects',
            contractTerminationForms: 'contracts/contractTerminationForms',
            contractTypes: 'contracts/contractTypes',
            contractValueClusters: 'contracts/contractValueClusters',
            resubmissionReasons: 'contracts/resubmissionReasons',
            resubmissionStates: 'contracts/resubmissionStates',
        }),
        userFields() {
            return [
                {   key: 'name',                label: 'Name'           },
                {   key: 'company',             label: 'Firma'          },
                {   key: 'actions',             label: 'Aktionen'       }
            ];
        },
        appendixFields() {
            return [
                //{   key: 'contract_id',                label: 'VertragsId'                                    },
                {   key: 'appendix_created_by.name',     label: 'Anlage erstellt durch'                         },
                {   key: 'appendix_name',                label: 'Anlage Name'                                   },
                {   key: 'appendix_type.appendix_type',  label: 'Anlage Typ'                                    },
                {   key: 'appendix_startdate',           label: 'Anlage Beginn', formatter: this.dateFormatter  },
                {   key: 'appendix_enddate',             label: 'Anlage Ende', formatter: this.dateFormatter    },
                {   key: 'appendix_description',         label: 'Beschreibung'                                  },
                {   key: 'appendix_keywords',            label: 'Schlagworte'                                   },
                {   key: 'appendix_filename',            label: 'Dateibezeichnung'                              },
                {   key: 'appendix_mimetype',            label: 'Dateityp'                                      },
                {   key: 'actions',                      label: 'Aktionen'                                      }
            ];
        },
        reminderFields() {
            return [
                //{   key: 'contract_id',                 label: 'VertragsId'                                       },
                {   key: 'reminder_created_by.name',    label: 'Erinnerung erstellt durch'                          },
                {   key: 'reminder_date',               label: 'Erinnerungsdatum', formatter: this.dateFormatter    },
                {   key: 'reminder_recipient_1_user.name',        label: 'Empfänger 1'                              },
                {   key: 'reminder_recipient_2_user.name',        label: 'Empfänger 2'                              },
                {   key: 'reminder_recipient_3_user.name',        label: 'Empfänger 3'                              },
                {   key: 'resubmission_day_cycle',      label: 'Wiedervorlage-zyklus'                               },
                {   key: 'resubmission_state',          label: 'Wiedervorlage-status'                               },
                {   key: 'resubmission_reason',         label: 'Wiedervorlage-grund'                                },
                {   key: 'resubmission_description',    label: 'Wiedervorlage-beschreibung'                         },
                {   key: 'resubmission_alert',          label: 'Eskalation Stufe'                                   },
                {   key: 'actions',                     label: 'Aktionen'                                           }
            ];
        },
        auctionFields() {
            return [
                {   key: 'id',                  label: 'Id'                                             },
                {   key: 'title',               label: 'Vergabe'                                        },
                {   key: 'organizer.name',      label: 'Veranstalter'                                   },
                {   key: 'start_date',          label: 'Vergabebeginn', formatter: this.dateFormatter   },
                {   key: 'end_date',            label: 'Vergabeende', formatter: this.dateFormatter     },
                {   key: 'actions',             label: 'Aktionen'                                       }
            ];
        },

    },
    watch: {
        auctionSearch: _.debounce(function(query) {
            this.searchAuction(query)
        } , 200)
    }
};
</script>
