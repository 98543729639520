<template>
    <b-modal ref="createNewReminderModal" hide-footer :title="reminderDialogTitle">
        <b-container>
            <b-form @submit.prevent="onSubmit">

                <b-row><b-col>
                    <b-form-group label="Datum Wiedervorlage" label-for="reminder-date">
                        <b-form-datepicker today-variant="info" v-model="reminder.reminder_date" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="reminder-date" :state="!$v.reminder.reminder_date.$error ? null : false" :placeholder="$t(`common.nodateselected`)">
                        </b-form-datepicker>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Wiedervorlagegrund" label-for="resubmission-reason">
                        <b-form-select text-field="resubmission_reason" value-field="id" :options="resubmissionReasons" v-model="reminder.resubmission_reason_id" :state="!$v.reminder.resubmission_reason_id.$error ? null : false"></b-form-select>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Beschreibung zur Wiedervorlage" label-for="resubmission-description">
                        <b-form-textarea id="resubmission-description" type="text" v-model="reminder.resubmission_description" rows="3"></b-form-textarea>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Tagezyklus für erneute Wiedervorlagen" label-for="resubmission-day-cycle">
                        <b-form-input id="resubmission-day-cycle" type="text" v-model="reminder.resubmission_day_cycle" :state="!$v.reminder.resubmission_day_cycle.$error ? null : false"></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Wiedervorlagestatus" label-for="resubmission-state">
                        <b-form-select text-field="resubmission_state" value-field="id" :options="resubmissionStates" v-model="reminder.resubmission_state_id" :state="!$v.reminder.resubmission_state_id.$error ? null : false"></b-form-select>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Empfänger 1" label-for="reminder-redcipient-1">
                        <b-form-select id="reminder-redcipient-1" text-field="name" value-field="id" :options="users" v-model="reminder.reminder_recipient_1"></b-form-select>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Empfänger 2" label-for="reminder-redcipient-2">
                        <b-form-select id="reminder-redcipient-2" text-field="name" value-field="id" :options="users" v-model="reminder.reminder_recipient_2"></b-form-select>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Empfänger 3" label-for="reminder-redcipient-3">
                        <b-form-select id="reminder-redcipient-3" text-field="name" value-field="id" :options="users" v-model="reminder.reminder_recipient_3"></b-form-select>
                    </b-form-group>
                </b-col></b-row>

                <b-row><b-col>
                    <b-form-group label="Warnungen zur Wiedervorlage" label-for="resubmission-alert">
                        <b-form-input id="resubmission-alert" type="text"  v-model="reminder.resubmission_alert" disabled></b-form-input>
                    </b-form-group>
                </b-col></b-row>

                <b-row>
                    <b-button class="button-margin" type="submit" variant="primary">
                        <b-iconstack>
                            <b-icon stacked icon="bell"></b-icon>
                            <b-icon stacked icon="plus" scale="0.7"></b-icon>
                        </b-iconstack>
                        {{reminderDialogSaveButtonText}}
                    </b-button>
                </b-row>

            </b-form>
        </b-container>
    </b-modal>
</template>
<script>
    import numberHelper from '../../../../../helpers/numberHelper';
    import objectHelper from '../../../../../helpers/objectHelper';
    import contractService from '../../../../../services/ContractService';
    import { transformToFormData } from '../../../../mixins/transformToFormData';
    import {mapGetters, mapActions} from 'vuex';
    import { helpers, required } from 'vuelidate/lib/validators';
    export default {
        name: 'create-reminder-modal',
        props: ['savedCallback', 'contract'],
        mixins: [transformToFormData],
        data() {
            return {
                currentReminderEditMode: false,
                reminder: {},
            }
        },
        validations: {
            reminder: {
                reminder_date:              { required },
                resubmission_reason_id:     { required },
                resubmission_day_cycle:     { required },
                resubmission_state_id:      { required },
            }
        },
        methods: {
            enterEditMode(item) {
                this.reminder = item;
                this.currentReminderEditMode = true;
                if (this.reminder.reminder_date) {this.reminder.reminder_date = this.$moment(this.reminder.reminder_date).toDate(); }
            },
            leaveEditMode() {
                this.currentReminderEditMode = false;
            },
            showModal() {
                this.$v.$reset();
                this.currentReminderEditMode = false;
                this.$refs.createNewReminderModal.show();
            },
            hideModal() {
                this.$refs.createNewReminderModal.hide();
                this.reminder = {};
                this.$v.$reset();
            },
            setReminder(reminder) {
                this.reminder = reminder;
            },
            onSubmit() {
                this.$v.$touch();

                if(this.$v.$error) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabeprüfung',
                        type: 'error',
                        text: 'Eingabe unvollständig oder fehlerhaft'
                    });
                    return;
                }

                this.$store.commit('loading/setLoading', true);
                if(!this.currentReminderEditMode) {
                    contractService.createReminder(this.contract.id, this.reminder)
                        .then(response => {

                            this.$notify({
                                    group: 'global',
                                    title: 'Wiedervorlage erstellt.',
                                    type: 'success',
                                    text: 'Die Wiedervorlage wurde dem Vertrag erfolgreich zugewiesen.'
                                });

                            this.$refs.createNewReminderModal.hide();

                            this.reminder = {};

                            if(this.savedCallback) {
                                this.savedCallback();
                            }
                        })
                        .catch(error => {
                            this.$store.commit('loading/setLoading', false);

                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Erstellen der Wiedervorlage.',
                                type: 'error',
                                text: error.message
                            });
                        })
                } else {
                    contractService.updateReminder(this.contract.id, this.reminder.id, this.transformToFormData(this.reminder,null,'PUT')).then(response => {

                        this.$notify({
                            group: 'global',
                            title: 'Wiedervorlage aktualisiert.',
                            type: 'success',
                            text: 'Die Wiedervorlage wurde erfolgreich aktualisiert.'
                        });

                        if(this.savedCallback) {
                                this.savedCallback();
                            }

                        this.$refs.createNewReminderModal.hide();

                        this.reminder = {};
                    })
                    .catch(error => {
                        this.$notify({
                                group: 'global',
                                title: 'Fehler beim Aktualisieren der Wiedervorlage.',
                                type: 'error',
                                text: error.message
                            });
                    })
                }
            },
        },
        computed: {
            ...mapGetters({
                users: 'contracts/users',
                resubmissionReasons: 'contracts/resubmissionReasons',
                resubmissionStates: 'contracts/resubmissionStates',
            }),
            reminderDialogTitle() {
                return !this.currentReminderEditMode ? 'Neue Wiedervorlage erstellen' : 'Wiedervorlage bearbeiten';
            },
            reminderDialogSaveButtonText() {
                return !this.currentReminderEditMode ? 'Zuweisen' : 'Aktualisieren';
            }
        }
    }
    </script>
